import React, { useEffect, useState } from "react";

const EstimateForm = () => {
  return (
    <>
   <section class="get_free_estimate get_free_estimate_new text-center">
        <div class="container">
          <div class="in_box">
            <div class="top">
              <h3>Get a free property estimate</h3>
              <p>
                Ready to sell your property? We’ll prepare a full appraisal
                report based on our vast local knowledge and market activity.
              </p>
              <div class="banner_from"> 
                <form
                  action="#"
                  method="GET"
                  id="frmApp2"
                >
                  <div class="input_box banner_from">
                    <input
                      type="text"
                      name="txtAddress"
                      class="form-control"
                      placeholder="Start typing your property address"
                      id="address-input2"
                      autocomplete="off"
                    />
                    <input type="hidden" name="hdAdd" id="hdAdd" />
                    <input type="hidden" name="hdLong" id="hdLong" />
                    <input type="hidden" name="hdLat" id="hdLat" />

                    <button type="submit" class="btn btn-black">
                      What’s my property worth?
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EstimateForm;
