import React, { useEffect, useState } from "react";
import search_icon from "../../images/search-icon.svg";
import products from "../../images/property/tn/img_1-127026.webp";
import products_img_2 from "../../images/property/tn/img_1-127025.webp";
import products_img_3 from "../../images/property/tn/img_1-127024.webp";

import parking_icon from "../../images/garage.jpg";
import bath_icon from "../../images/bath.jpg";
import bed_icon from "../../images/bed.jpg";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";

const Sold = ({ setClassName }) => {


  const [isToggled, setIsToggled] = useState(false); // Initialize the class state to false.

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled); // Toggle the class state on button click.
  };

  const [rangeValue, setRangeValue] = useState(50); // Initial value, change as needed
  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
  }; 

  useEffect(() => {
    setClassName("property_listing_screen");
    return () => {
      setClassName("");
    };
  }, []);
  const properties = [
   {
      tag: "For Sale",
      price: '$589,500',
      info : "Available on   Request",
      title: "DEANSIDE",      
      img: products,
      parking_icon: parking_icon,
      bath_icon: bath_icon,
      bed_icon: bed_icon,
      bath: "2",
      bed: "3",
      parking: "1",
    },
    {
      tag: "For Sale",
      price: 'Contact agent',
      info : "17   CROXDEN AVENUE",
      title: "THORNHILL PARK",      
      img: products_img_2,
      // parking_icon: parking_icon,
      // bath_icon: bath_icon,
      // bed_icon: bed_icon,
      // bath: "2",
      // bed: "3",
      // parking: "1",
    },
    {
      tag: "For Sale",
      price: '$604,255',
      info : "LOT on  Ostic Way",
      title: "TARNEIT",      
      img: products_img_3,
      // parking_icon: parking_icon,
      // bath_icon: bath_icon,
      // bed_icon: bed_icon,
      // bath: "2",
      // bed: "3",
      // parking: "1",
    },
    {
      tag: "For Sale",
      price: '$589,500',
      info : "Available on   Request",
      title: "DEANSIDE",      
      img: products,
      parking_icon: parking_icon,
      bath_icon: bath_icon,
      bed_icon: bed_icon,
      bath: "2",
      bed: "3",
      parking: "1",
    },
    {
      tag: "For Sale",
      price: 'Contact agent',
      info : "17   CROXDEN AVENUE",
      title: "THORNHILL PARK",      
      img: products_img_2,
      // parking_icon: parking_icon,
      // bath_icon: bath_icon,
      // bed_icon: bed_icon,
      // bath: "2",
      // bed: "3",
      // parking: "1",
    },
    {
      tag: "For Sale",
      price: '$604,255',
      info : "LOT on  Ostic Way",
      title: "TARNEIT",      
      img: products_img_3,
      // parking_icon: parking_icon,
      // bath_icon: bath_icon,
      // bed_icon: bed_icon,
      // bath: "2",
      // bed: "3",
      // parking: "1",
    },
  ]
  return (
    <>
      
      <div id="Listing-2" className={isToggled ? 'listing_screen mobile_filter_box section padding-0 wf-section active' : 'listing_screen mobile_filter_box section padding-0 wf-section'} style={{marginBottom:'100px'}} >
      <div class="listing-2-filter" style={{position: 'fixed', zIndex:'999' }}>
        <div class="container-fluid">
          <div class="listing-2-filter-holder">
            <div class="list_filter_box">
              <div class="filter-form w-form">
                <form id="" name="" data-name="search" method="get">
                  <div class="search-wrap">
                  <i class="fa-regular fa-magnifying-glass search_icon"></i>
                  {/* <img className="search_icon"
                      src={search_icon} 
                      loading="lazy" alt="" />*/}
                  <input type="text" class="text-field search-field w-input" maxlength="256"
                      name="location" data-name="location" placeholder="Search by Address" id="City" />
                      </div>
                </form>

              </div>
              <select class="form-control">
                <option>For Sale</option>
              </select>
             
              {/* <div class="drop_select">
              <select class="form-control multiple-checkboxes" id="suburbs" multiple="multiple">  
                 
                </select>  
              </div>
              <select class="form-control">
                <option>Residential</option>
              </select>  */}
              <div class="drop_select">
                <select id="property" class="form-control multiple-checkboxes" >  
                  <option>Property Type</option>
                </select>
              </div>
              <Dropdown className="drop_select dropdown button_icon">
              <Dropdown.Toggle as="button" className="btn multiselect dropdown-toggle">
                 Price
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  <h5>Price</h5>  
                  <div>
                  <label className="mb-2">
                      <span id="min"></span> $0 to <span id="max">${rangeValue}</span>
                    </label>  
                    <Form.Range value={rangeValue} onChange={handleRangeChange} />
                    
                  </div>
                </Dropdown.Menu>
              </Dropdown>

             
           
              
               <Dropdown className="drop_select dropdown button_icon">
               <Dropdown.Toggle as="button" className="btn multiselect dropdown-toggle">
                 Bed
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  <h5>Bed</h5>  
                  <ul>
                    <li><label class=""><input type="radio" name="root"/> <span>Any</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>1</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>2</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>3</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>4</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>5+</span></label></li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="drop_select dropdown button_icon">
               <Dropdown.Toggle as="button" className="btn multiselect dropdown-toggle">
                 Bath
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  <h5>Bath</h5>  
                  <ul>
                    <li><label class=""><input type="radio" name="root"/> <span>Any</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>1</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>2</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>3</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>4</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>5+</span></label></li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="drop_select dropdown button_icon">
               <Dropdown.Toggle as="button" className="btn multiselect dropdown-toggle">
                 Car
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  <h5>Car</h5>  
                  <ul>
                    <li><label class=""><input type="radio" name="root"/> <span>Any</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>1</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>2</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>3</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>4</span></label></li>
                    <li><label class=""><input type="radio" name="root"/> <span>5+</span></label></li>
                  </ul>
               </Dropdown.Menu>
              </Dropdown>
             
              
             
            </div>
          </div>
        </div>
      </div>
      </div>
    

        <div
          id="Listing-3"
          className="listing_section section pb-5 wf-section"
        >
          <div className="container">
            <div className="top text-center listing_top_heading listing_top_heading_filter mb-2">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="filter_link" onClick={handleButtonClick}>Filters</div>
                  <h2>Recently Sold Properties</h2>
                  <h6>Showing 1 - 7 of 7 properties</h6>
                </div>
                <div className="col-sm-6">
                <div className="sort_option">
                <label>Sort by</label>
                  <select>
                      <option>Sort</option>
                  </select>
                </div>
                  {/* <div className="grid_option">
                    <ul className="grid_list">
                      <li className="active">
                        <a href="#">
                          <i className="fa-solid fa-grid-2"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-light fa-map-location-dot"></i>
                        </a>
                      </li>
                    </ul>
                    <select className="form-control">
                      <option>Sort</option>
                    </select>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row" data-id="tab1" role="list">
              {properties.map((propertie, index) => (              
            <div role="listitem" key={index} className="text-center text-sm-start  text-md-start col-12 col-sm-6 col-xl-4 col-xxl-4 mb-4">
                <Link to="/properties-detail" className="listing-card-2 w-inline-block w100p ms-auto me-auto">
                  <div className="listing-2-card-top">
                    {/* <div className="sale_tag">{propertie.tag}</div> */}
                    <img
                      src={propertie.img}
                      className="listing-card-2-photo"
                      height="380px"
                    />
                    <div className="listing-2-price" style={{background: '#F6D849', color: '#000', fontWeight: '600'}}>
                      <div className="listing-2-price-text">{propertie.tag} | {propertie.price}</div>
                      <div className="listing_2_price">
                      {/* <h6>addsdsd</h6> */}
                      {/* <img
                      src={propertie.img}
                      className="listing-card-2-photo"
                    /> */}
                    </div>
                    </div>
                  </div>
                  <div className="listing-content-2 listing_content_2_new">
                    <div className="text-medium margin-top-4">
                      {propertie.info} <b>{propertie.title}</b>
                    </div>
                    {/* <h5></h5> */}
                    <div className="listing-2-card-features">
                      <div className="features first-item">
                        <img
                          src={propertie.bed_icon}
                          className="features-icon"
                        />
                        <div className="text-medium">
                          <span style={{ color: "#000" }}>{propertie.bed} </span>
                        </div>
                      </div>
                      <div className="features">
                        <img
                          src={propertie.bath_icon}
                          className="features-icon"
                        />
                        <div className="text-medium">
                          <span style={{ color: "#000" }}>{propertie.bath} </span>
                        </div>
                      </div>
                      <div className="features">
                        <img
                          src={propertie.parking_icon}
                          className="features-icon"
                        />
                        <div className="text-medium">
                          <span style={{ color: "#000" }}>{propertie.parking}</span>
                        </div>
                      </div>
                    </div>
                    <h5>Sold date: 21 June 2023</h5>
                  </div>
                </Link>
              </div> 
                    ))}

          
              
              
            </div>
          </div>
        </div>
    </>
  );
};

export default Sold;
