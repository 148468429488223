import React, { useEffect, useState } from "react";
import careersPhoto500 from '../../images/Careers-photos02-p-500.jpeg';
import careersPhoto760 from '../../images/Careers-photos02.jpg';
import team_1 from "../../images/team-1.jpg";
import team_2 from "../../images/team-2.jpg";
import team_3 from "../../images/team-3.jpg";
import team_4 from "../../images/team-4.jpg";
import team_5 from "../../images/team-5.jpg";
import team_01 from "../../images/team-01.png";
import team_02 from "../../images/team-02.png";
import team_03 from "../../images/team-03.png";
import marketing_leads from "../../images/marketing-leads.jpg";
import compensation from "../../images/compensation.jpg";
import technology1 from "../../images/technology1.jpg"; 

const JoinOurTeam = ({ setclassPropWrap }) => {

  const positions = [
    {title: "Business Development Manager", jobposition: "All Melbourne"},
    {title: "New Homes Sales Consultant", jobposition: "Melbourne Western Suburbs"},
    {title: "Real Estate Agent", jobposition: "All Melbourne"},
    {title: "Real Estate Agent", jobposition: "Melbourne Western Suburbs"},
    {title: "Real Estate Sales Agent", jobposition: "Tarneit, Truganina, Point Cook, Hoppers Crossing"},
    {title: "Sales Prospecting & Cold Calling Specialist", jobposition: "All Melbourne"},
  ] 
 
  const iframeStyle = {
    width: "100%",
    height: "847px",
    border: "none",
    borderRadius: "3px",
  };
  
  useEffect(() => {
    setclassPropWrap("join_team_page join_our_team_page");
    return () => {
      setclassPropWrap("");
    };
  }, []);
  return (
    <>
      <div className="section overflow padding-bottom-0 wf-section banner">
        <div className="container">
          <div className="grid">
            <div
              id="w-node-cd160a8c-ad76-8b32-0818-f54aea376524-86d46fb4"
              className="column-large text-center"
            >
              <h6>Join our team</h6>
              <h1 className="margin-bottom-0">
                A real estate company <br />
                that thinks differently <br />
                from the rest
              </h1>
            </div>
          </div>
        </div>

        <div className="careers-hero">
          <img
            src={team_1}
            loading="lazy"
            width="350"
            alt=""
            className="careers-image _1"
          />

          <img
            src={team_2}
            loading="lazy"
            width="350"
            sizes="(max-width: 991px) 30vw, 350px"
            srcSet={`${careersPhoto500} 500w, ${careersPhoto760} 760w`}
            alt=""
            className="careers-image _2"
          />

          <img
            src={team_3}
            loading="lazy"
            width="350"
            sizes="(max-width: 991px) 50vw, 550px"
            srcSet=""
            alt=""
            className="careers-image _3"
          />

          <img
            src={team_4}
            loading="lazy"
            width="350"
            sizes="(max-width: 991px) 30vw, 350px"
            srcSet={`${team_4} 500w, ${team_4} 760w`} 
            alt=""
            className="careers-image _4"
          />

          <img
            src={team_5}
            loading="lazy"
            width="350"
            alt=""
            className="careers-image _5"
          />
        </div>
      </div>

      <section className="empower_section">
        <div className="container">
          <div className="text-center">
            <h2>Empower you to be the best agent of your area</h2>

            <h6>
              Work smarter & independently for yourself and Keep up to 100% of
              your commission. 
            </h6>

            <a href="#Contact-Form-2-Section" className="button-cta w-inline-block btn-black">
              <div>Get Started Now</div>
            </a>
          </div>

          <div className="middle text-center">
            <div className="text-center">
              <h2>Be part of the best</h2>

              <h6>
                Become part of something new and exciting organisation with
                forward-thinkers who are pushing the boundaries. 
              </h6>
            </div>

            <p style={{fontWeight:600}}> 
              It's a new age for real estate agents, and Magicbricks is changing
              how agents work. The company was formed with an ambitious mission:
              to create highly experienced, motivated, and positive real estate
              professionals to serve the client’s needs better.
            </p>

            <p style={{fontWeight:600}}>
              We have created a welcoming environment where we value integrity,
              honesty, and hard work. Magicbricks offers access to the most
              advanced and powerful industry tools in real estate, which will
              help you succeed.
            </p>

            <p style={{fontWeight:600}}>
              We offer our agent partners the opportunity to earn & keep 100% of
              their commission. Work independently from anywhere without any
              restrictions. 
            </p>
          </div>
        </div>
      </section>

      <div className="about_info">
        <div className="container">
        <div className="about_info_box">
      <div className="row align-items-center">

      <div className="col-sm-6 order-1">
      <h6 style={{color: '#888'}}>Marketing &amp; Leads</h6>
          <h3>A real investment in you</h3>
<p>All of our agents benefit from the significant investment 
we make in marketing and advertising to generate leads.
In addition, we allocate dedicated marketing budgets 
for teams and individual agents, while offering personalized 
coaching sessions to help you enhance your social media 
strategy and online presence.</p>
        </div>
        <div className="col-sm-6 order-2 text-left">
          <img src={marketing_leads} style={{maxWidth: '60%'}}/>
        </div>
        
      </div>
    </div>
  <div className="about_info_box">
      <div className="row align-items-center">
        <div className="col-sm-6 order-1 text-left">
          <img src={compensation} style={{maxWidth: '60%'}}/>
        </div>
        <div className="col-sm-6 order-2">
        <h6 style={{color: '#888'}}>Compensation</h6>
          <h3>Industry-high splits <br/>
with no minimums</h3>
<p>We not only provide highly competitive splits, but we 
also reinvest back into your success. Unlike the 
traditional agency model, we want everyone 
to succeed.</p>
        </div>
      </div>
    </div>
  <div className="about_info_box">
      <div className="row align-items-center">
      <div className="col-sm-6 order-1">
      <h6 style={{color: '#888'}}>Technology</h6>
          <h3>Best-in-class <br/>
tech for agents</h3>
<p>Maximize your sales potential by utilizing our comprehensive 
technology suite and administrative support. Take advantage 
of the industry’s leading follow-up CRM, agentsbio platform, 
and communication tools, enabling you to deliver an 
exceptional experience to each and every client.</p>
        </div>
        <div className="col-sm-6 order-2 text-left">
          <img src={technology1} style={{maxWidth: '60%'}}/>
        </div>
        
      </div>
    </div>


          <div className="about_info_box">
            <div className="row align-items-center">
              <div className="col-sm-6 order-1 text-left">
                <img src={team_01} />
              </div>
              <div className="col-sm-6 order-2">
              <h6 style={{color: '#888'}}>Support & Training</h6>
                <h3>
                  We choose the best <br />
                  people & train them <br />
                  to become top experts.
                </h3>
                <p>
                  Smart, ambitious, open-minded, hard-working and friendly
                  individuals with a desire to overcome challenges - those are
                  the kinds of people that work at Magicbricks. We select our
                  agents carefully, because being able to count on them and
                  learn from each other is vital.
                </p>
              </div>
            </div>
          </div>
          <div className="about_info_box share_box">
            <h3 className="text-center">
              We share the same values which <br />
              makes our team stronger
            </h3>
            <div className="row">
              <div className="col-sm-6 order-1 text-left">
                <img src={team_02} />
              </div>
              <div className="col-sm-6 order-2">
                <h6>Do everything with care</h6>
                <p>
                  for each other, our clients, our communities, and our
                  environment.
                </p>
                <h6>Work with purpose</h6>
                <p>be driven by curiosity, passion, and making an impact.</p>
                <h6>Respect yourself and others</h6>
                <p>
                  regardless of their race, religion, ethnicity, age, gender or
                  else.
                </p>
                <h6>Challenge yourself</h6>
                <p>try to always deliver more than expected.</p>
                <h6>Innovate through technology</h6>
                <p>raise the bar of what’s possible in our industry.</p>
              </div>
            </div>
          </div>
          <div className="about_info_box">
            <div className="row align-items-center">
              <div className="col-sm-6 order-2 text-right">
                <img src={team_03} />
              </div>
              <div className="col-sm-6 order-1">
              <h6 style={{color: '#888'}}>Culture</h6>
                <h3>We invest in our people’s happiness.</h3>
                <p>
                  Happy people are eager to grow and make the impossible
                  possible. That's why we invest in our employees' happiness. We
                  make sure our offices are comfortable, inspiring, and
                  motivating places and provide our team members with the tools
                  and support they need to live a healthy, balanced work life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="our_structure">
        <div className="container">
          <h3 className="text-center">Our structure</h3>
          <div className="our_structure_box">
            <div className="row">
              <div className="col-sm-6">
                <h6>Independent/Mobile agent</h6>
                <p>
                  <b>
                    $15,000 per annum exclusive GST. No upfront, paid only once
                    you begin selling the listings.
                  </b>
                </p>
                <ul className="list_items">
                  <li>
                    {" "}
                    Advertise on realestate, domain, homely, digioffer and other
                    portals
                  </li>
                  <li>Access to property CRM</li>
                  <li>Access to lead generation software </li>
                  <li>
                    Access to sales & marketing automation software (salesboss)
                  </li>
                  <li>Access to forms & letters</li>
                  <li>Pricefinder access</li>
                  <li>Professional email suite platform</li>
                  <li>Agents.bio account</li>
                  <li>
                    Access to digital offer management (Digioffer). Receive
                    digital offers, check-in visitors
                  </li>
                  <li>Full support</li>
                </ul>
              </div>
              <div className="col-sm-6">
          <h6>Team/Office</h6>
          <p><b>$30,000 per annum exclusive GST. Paid on half 
yearly basis.   </b></p>
<ul className="list_items">
  <li> Advertise on realestate, domain, homely, digioffer and 
     other portals</li>
 <li>
Access to property CRM</li>
<li>
Access to lead generation software </li>
<li>
Access to sales &amp; marketing automation software (salesboss)</li>
<li>
Access to forms &amp; letters</li>
<li>
Pricefinder access</li>
<li>
Professional email suite platform
</li>
<li>
Agents.bio account</li>
<li>
Access to digital offer management (Digioffer). Receive 
     digital offers, check-in visitors
     </li>
     <li>
Full support</li>
</ul>
</div>
            </div>
            <div className="col-sm-12 text-center">
              <div className="inline_buttons">
                <a href="#" className="link">
                  Any questions?
                </a>
                <a href="#" className="w-button btn-black">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section padding-bottom-0 wf-section open_positions">
        <div className="container">
          <h3 className="text-center">Open positions</h3>

          <div className="careers-wrap w-dyn-list">
            <div role="list" className="grid-halves spacer-24 w-dyn-items">
            {positions.map((position, index) => (
              <div role="listitem" key={index} className="w-dyn-item">
                <a href="#" className="careers-card w-inline-block">
                  <div>{position?.title}</div>
                  <div className="text-small color-secondary-1 margin-top-4">
                    {position?.jobposition}
                  </div>
                </a>
              </div>
            ))}

            </div>
          </div>
        </div>
      </div>

      <div
        id="Contact-Form-2-Section"
        className="section wf-section careers_contact_form pb-5"
      >
        <div className="container">
          <div className="top">
            <h6>ARE YOU EXCITED TO LEARN MORE?</h6>

            <h2>Get in touch with us</h2>

            <p>
              Magicbricks is a new way to get your own real estate business
              started. <br />
              Agents will be provided with all the tools and training needed to
              succeed.
            </p>
          </div>
          <div className="grid">
            <div
              id="w-node-_8899ed14-a4a0-6320-5076-a1ad9d42f398-f9d46fc1"
              className="column-medium"
            >
              <div>
                <iframe
                  src="https://api.leadconnectorhq.com/widget/form/cnDhFCyH4kwAMphOqHdL"
                  style={iframeStyle}
                  id="inline-cnDhFCyH4kwAMphOqHdL"
                  data-layout='{"id":"INLINE"}'
                  data-trigger-type="alwaysShow"
                  data-trigger-value=""
                  data-activation-type="alwaysActivated"
                  data-activation-value=""
                  data-deactivation-type="neverDeactivate"
                  data-deactivation-value=""
                  data-form-name="Career"
                  data-height="939"
                  data-layout-iframe-id="inline-cnDhFCyH4kwAMphOqHdL"
                  data-form-id="cnDhFCyH4kwAMphOqHdL"
                  title="Career"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default JoinOurTeam;
