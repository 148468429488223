import React, { useEffect, useState } from "react";
import tnimg_1_127030 from "../../images/property/tn/img_1-126967.webp"; 
import {SlideshowLightbox} from 'lightbox.js-react';

const PropertiesDetail = ({ setClassName }) => {
  useEffect(() => {
    setClassName("property_detail_page");
    return () => {
      setClassName("");
    };
  }, []);
  return (
    <>
    
  

      <div className="section padding-0 wf-section banner_grid">
        <div className="container">
        <div className="SlideshowLightbox_box">
        <ul class="images_con">
    			<li class="active"><i class="fa-light fa-image"></i> 12</li>
    		</ul>
        <SlideshowLightbox className="grid_box">        
        <img className="w-full rounded" src={tnimg_1_127030} />
        <img className="w-full rounded" src={tnimg_1_127030} />  
    <img className="w-full rounded" src={tnimg_1_127030} />
    <img className="w-full rounded" src={tnimg_1_127030} />
          
          </SlideshowLightbox>  
          </div>
        </div>
      </div>
      <div className="property_detail_screen section padding-bottom-0 wf-section">
        <div className="container">
          <div className="flex-stretch-space listings-cms-content">
            <div className="column-medium">
              <div className="grid spacer-80">
                <div>
                  <div className="flex-center details-wrap">
                    <div className="flex-center property-type">
                      <div className="text-featured">Property Type:</div>
                      <div className="text-featured property-type">House</div>
                    </div>
                  </div>
                  <h1 className="margin-top-24">
                    lot 1772, Councillor Drive, WEIR VIEWS, VIC, 3338{" "}
                    <a
                      href="https://google.com/maps/search/lot 1772, Councillor Drive, WEIR VIEWS, VIC, 3338"
                      target="_blank"
                      className="google-maps-icon w-inline-block"
                    >
                      <img
                        src="https://assets.website-files.com/60f685550f9f4d3b537d1987/610036f894ffb457baaea7a9_Google%20Maps%20Icon.svg"
                        loading="lazy"
                        alt=""
                      />  
                    </a>
                  </h1>

                  <div className="grid-quarters grid_quarters">
                    <div className="listings-sticky-item">
                      <img
                        src="https://www.magicbricks.com.au/images/bed.jpg"
                        loading="lazy"
                        alt=""
                        className="listings-sticky-icon"
                      />
                      <div>4</div>
                    </div>
                    <div className="listings-sticky-item">
                      <img
                        src="https://www.magicbricks.com.au/images/bath.jpg"
                        loading="lazy"
                        alt=""
                        className="listings-sticky-icon"
                      />
                      <div>2</div>
                    </div>

                    <div className="listings-sticky-item">
                      <img
                        src="https://www.magicbricks.com.au/images/garage.jpg"
                        loading="lazy"
                        alt=""
                        className="listings-sticky-icon"
                      />
                      <div>2</div>
                    </div>
                    <div
                      id="w-node-_1b3408d2-529e-d63f-c666-a9a6fd67b8ed-8a1f3361"
                      className="listings-sticky-item"
                    >
                      <img
                        src="https://www.magicbricks.com.au/images/area.jpg"
                        loading="lazy"
                        alt=""
                        className="listings-sticky-icon"
                      />
                      <div>
                        <span>
                          350 m<sup>2</sup>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="side_bar" style={{ display: "none" }}>
                    <div className="white_box">
                      <h3> $685,780 - $700,780 </h3>

                      <ul className="floor_list">
                        <li>
                          <i className="fa-light fa-map"></i>{" "}
                          <a
                            href="#"
                            target="_blank"
                          >
                            Download Brochure
                          </a>
                        </li>
                      </ul>
                      <h4 className="mt-40">Inspection Details</h4>
                      <p>No upcoming inspection </p>
                      <a href="#" className="button-cta rounded w-inline-block">
                        Add to Calendar
                      </a>
                    </div>
                  </div>
                </div>
                <div className="custom-rich-text w-richtext">
                  <h2>
                    Complete Turn-Key Home in Prime Location: Fixed Site Costs
                    with 4-Month Build Guarantee!
                  </h2>
                  Magicbricks is thrilled to introduce this stunning house and
                  land package, conveniently located on Councillor Dr, Weir
                  Views. Experience the comfort of having numerous local
                  amenities within immediate reach. Seize this extraordinary
                  opportunity now.
                  <br />
                  <br />
                  Turn-Key Perfection with Fixed Site Costs! Unearth the
                  ultimate lifestyle with this house that delivers all the key
                  components for comfortable and stylish living. Here are the
                  salient features of your new home:
                  <br />- Fixed site costs, including rock &amp; piers.
                  <br />- Impressive 2590mm ceiling height.
                  <br />- 600mm built-in Cooker &amp; Canopy Rangehood.
                  <br />- Stainless Steel Dishwasher for ease and convenience.
                  <br />- Ducted Heating for those chilly nights.
                  <br />- Beautiful Timber Laminate Floors.
                  <br />- Comfy Carpets to all Bedrooms.
                  <br />- LED Downlights to all rooms for bright and efficient
                  lighting.
                  <br />- Three Coat Paint System for long-lasting durability.
                  <br />- Bushfire BAL 12.5 compliant for added safety.
                  <br />- NBN Ready for seamless internet connectivity.
                  <br />- Corner treatment requirements.
                  <br />- 6-Star Energy Compliant for environmentally friendly
                  living.
                  <br />- Developer Guidelines adhered to, including Recycled
                  water, water tanks &amp; Eaves.
                  <br />
                  <br />
                  Additionally, your home comes with the following turn-key
                  upgrades:
                  <br />- Elegant 20mm Stone Benchtops to Kitchen.
                  <br />- Split System Air Conditioner for temperature control.
                  <br />- Blinds to all Windows for privacy and light control.
                  <br />- Concrete Driveway &amp; Porch (Colour Through).
                  <br />- Front, Side, &amp; Rear Landscaping for a picturesque
                  outdoor environment.
                  <br />- Full Fencing for privacy and security.
                  <br />- Clothesline &amp; Letterbox for practical essentials.
                  <br />
                  <br />
                  Every aspect of this home has been meticulously planned to
                  ensure the highest quality living experience.
                  <br />
                  <br />
                  Contact KIRPAL at 0412 271 035 TODAY!
                  <br />
                  <br />
                  DISCLAIMER: All stated dimensions are approximate only.
                  Particulars given are for general information only and do not
                  constitute any representation on the part of the vendor or
                  agent.
                  <br />
                  http://www.consumer.vic.gov.au/duediligenchecklist{" "}
                </div>
                <div></div>

                <div></div>
              </div>
            </div>
            <div className="side_bar listings_sticky">
              <div className="white_box">
                <h3> $685,780 - $700,780 </h3>

                <ul className="floor_list">
                  <li>
                    <i className="fa-light fa-map"></i>{" "}
                    <a
                      href="https://www.mantisproperty.com.au/database/images/agent440/440_residential_121463.pdf"
                      target="_blank"
                    >
                      Download Brochure
                    </a>
                  </li>
                </ul>
                <h4 className="mt-40">Inspection Details</h4>
                <p>No upcoming inspection </p>
              </div>
              <div className="white_box contact_agent">
                <h2>Contact Agent</h2>
                <div className="w-dyn-list">
                  <div role="list" className="grid-halves w-dyn-items">
                    <div role="listitem" className="w-dyn-item">
                      <span className="agents-item">
                        <a href="pritpal ">
                          <img
                            src="https://www.magicbricks.com.au/images/agents/agent868.jpg"
                            loading="lazy"
                            alt=""
                            sizes="(max-width: 479px) 100vw, (max-width: 991px) 180px, 11vw"
                            srcSet="https://www.magicbricks.com.au/images/agents/agent868.jpg"
                            className="agents-avatar"
                          />
                        </a>
                        <div className="text-large">
                          <a href="pritpal ">Prit Pal</a>
                        </div>
                        <div className="color-secondary-1">0424415110</div>
                        <div className="color-secondary-1">
                          <u>
                            <div
                              style={{ cursor: "pointer" }}
                            >
                              Email Prit Pal
                            </div>
                          </u>
                        </div>
                      </span>
                    </div>

                    <div role="listitem" className="w-dyn-item">
                      <span className="agents-item">
                        <div className="text-large">
                          <a href="kirpal.tejay ">Kirpal Tejay</a>
                        </div>
                        <div className="color-secondary-1">0412 271 035</div>
                        <div className="color-secondary-1">
                          <u>
                            <div
                              style={{ cursor: "pointer" }}
                            >
                              Email Kirpal Tejay
                            </div>
                          </u>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesDetail;
