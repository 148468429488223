import React, { useEffect, useState } from "react";
import about_img_3 from "../../images/about_img_3.jpg";
import about_img_2 from "../../images/about_img_2.jpg";
import about_01 from "../../images/about_01.jpg";
import About_thumb04 from "../../images/About-thumb04.jpg";
import About_thumb03 from "../../images/About-thumb03.jpg";
import About_thumb02 from "../../images/About-thumb02.jpg";
import About_thumb01 from "../../images/About-thumb01.jpg";

const Builder = ({setClassName}) => {
  useEffect(()=>{
    setClassName("about_us");
    return()=>{
      setClassName("");
    }
  },[])
  return (
    <> 
    builder
    </>
  );
};

export default Builder;
