 
import './App.css'; 
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import PublicRoutes from './routes/publicRoutes';
import AppLayout from './routes/layout';

 
import ProtectedERoutes from './routes/protctedRoutes';

import { lazy, useEffect, useState } from 'react';
import PropertiesForSale from './views/properties-for-sale';
import AboutUs from './views/about-us';
import ContactUs from './views/contact-us';
import Sell from './views/sell';
import SellYourProperty from './views/sell-your-property';
import Team from './views/team';
import Builder from './views/builder';
import Terms from './views/terms';
import PrivacyPolicy from './views/privacy-policy';
import Ndis from './views/ndis';
import Articles from './views/articles';
import Estates from './views/estates';
import JoinOurTeam from './views/join-our-team';
import PropertiesDetail from './views/properties-detail';
import {initLightboxJS} from 'lightbox.js-react';
import HomeLand from './views/home-land';
import Sold from './views/sold';
// import Home from './views/home';
const Home = lazy(() => import('./views/home'));  

function App() {

  const ScrollToTop = () => {
    const {pathname} = useLocation()
    useEffect(() => {
      initLightboxJS("Insert your License Key here", "Insert plan type here");
      window.scrollTo(0, 0);
    }, [pathname]);
    return null
  } 
  
 const AllRoutes = () =>{
  const [className,setClassName] = useState("");
  const [classNameWrap,setclassNameWrap] = useState("");
  // const [name,setName] = useState("Pardeep");
  return(
    <>
    <ScrollToTop/>
    <Routes>   
    <Route path='' element={<PublicRoutes><AppLayout classPropWrap={classNameWrap} classProp={className}/></PublicRoutes>}>
    <Route path='/' element={<Home /> }></Route>
    <Route path='properties-for-sale' element={<PropertiesForSale setClassName={setClassName}/> }></Route>
    <Route path='home-and-land' element={<HomeLand setClassName={setClassName}/> }></Route>
    <Route path='Sold' element={<Sold setClassName={setClassName}/> }></Route>
    <Route path='about-us' element={<AboutUs setclassPropWrap={setclassNameWrap} setClassName={setClassName}/> }></Route>
    <Route path='contact-us' element={<ContactUs/> }></Route>
    <Route path='sell' element={<Sell setclassPropWrap={setclassNameWrap} setClassName={setClassName}/> }></Route>
    <Route path='sell-your-property' element={<SellYourProperty setclassPropWrap={setclassNameWrap} setClassName={setClassName}/> }></Route>
    <Route path='team' element={<Team setClassName={setClassName}/>}></Route>
    <Route path='builder' element={<Builder setclassPropWrap={setclassNameWrap} setClassName={setClassName}/>}></Route>
    <Route path='builder' element={<Builder setclassPropWrap={setclassNameWrap} setClassName={setClassName}/>}></Route>
    <Route path='terms' element={<Terms />}></Route>
    <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>
    <Route path='ndis' element={<Ndis />}></Route>
    <Route path='articles' element={<Articles setClassName={setClassName}/>}></Route>
    <Route path='estates' element={<Estates setClassName={setClassName}/>}></Route>
    <Route path='join-our-team' element={<JoinOurTeam setclassPropWrap={setclassNameWrap} setClassName={setClassName}/>}></Route>
    <Route path='properties-detail' element={<PropertiesDetail setclassPropWrap={setclassNameWrap} setClassName={setClassName}/>}></Route>
    </Route>
      {/* <Route path='/login' element={<Loginpage /> }></Route> 
      <Route path='/register' element={<Registerpage /> }></Route>
    </Route>

    <Route path='' element={<ProtectedERoutes><AppLayout/></ProtectedERoutes>}></Route>
      <Route path='/dashboard' element={<DashboardPage /> }></Route>
      <Route path='/buttons' element={<ButtonsPage /> }></Route>
      <Route path='/table' element={<Tables /> }></Route>
      <Route path='/tablesdetail/:id' element={<TablesDetail /> }></Route>
     */}
  </Routes>
  </>
  )
 }
  return (
    
    <AllRoutes/>
   
  );
}



export default App;
