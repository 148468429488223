import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Outlet } from 'react-router-dom';
import Header from "../../views/header";
import Footer from "../../views/footer";



const AppLayout = (props) => {
  const {classProp, setName} = props
  const {classPropWrap, setclassPropWrap} = props

  return (
    <>   
    <div className={classPropWrap}>
      <div className={`page-wrap ${classProp}`}>          
      {/* className={`page-wrap ${classProp}`} */}
          <Header/> 
          <Outlet />  
          <Footer/>
      </div>       
    </div> 
    </>
  );
};
export default AppLayout;
