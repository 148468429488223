import React, { useEffect, useState } from "react";
import we_support from "../../images/we-support.jpg";
import sda from "../../images/sda.jpg";
import ur_interest from "../../images/ur-interest.jpg";
import howcan_thishelp from "../../images/howcan-thishelp.jpg";
import showme from "../../images/showme.jpg"; 

const Ndis = () => {
  return (
    <>
      <div className="banner_new">
        <div className="container-fluid">
          <div className="form_banner_ndis">
            <div className="grid">
              <div
                id="w-node-f429e44b-c430-87b3-26b8-735c96ded2c8-ead47014"
                className="column-large text-center"
              >
                <h2 className="margin-bottom-0">
                  We Build <br />
                  NDIS Homes
                </h2>
                <p style={{color: '#fff'}}>Want to know more?</p>
                <a
                  href="#"
                  className="btn btn-black btn-yellow"
                  id="openModal"
                  style={{marginTop: '0px'}}
                >
                  Get in Touch
                </a>

                <div id="result"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="smart_section" style={{marginBottom: '0px'}}>
        <div className="container">
          <div className="top text-center mb-0">
            <h3>What is the NDIS?</h3>
            <p>
              A new way of providing support to Australians with a <br />{" "}
              disability, their families, and careers.
            </p>
          </div>
        </div>
      </section>

      <section
        className="secret_properties"
        style={{background: '#f7f4f2', padding: '80px 0px', marginTop: '0px'}}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6 left">
              <h3>We support the NDIS</h3>
              <p>
                NDIS – National Disability Insurance Scheme Rolled out in 2016
                to help assist the 14% (or around 4.3 million) Australians, that
                have a disability.
              </p>
              <p>
                A bipartisan Federal Government agreement that will run for 20
                years with a budget of $26 Billion per year.
              </p>
              <p>
                When fully rolled out, the NDIS will provide about 460,000
                Australians aged under 65, who have permanent and significant
                disability with funding for supports and services.
              </p>
            </div>
            <div className="col-sm-6 right">
              <img className="w80p" src={we_support} />
            </div>
          </div>
        </div>
      </section>

      <section className="secret_properties">
        <div className="container width-less">
          <div className="row">
            <h3 align="center">What is SDA</h3>
            <div className="col-sm-6 col-md-5">
              <img
                className="w80p padding-zero"
                src={sda}
              />
            </div>
            <div className="col-sm-6 col-md-7">
              <h6>SDA – Specialist Disability Accommodation</h6>
              <br />
              <p>
                SDA, is an NDIS introduced funding stream to build new
                accessible and affordable housing for 28,000 Australians with a
                disability.
              </p>
              <p>
                The NDIS has an annual recurrent budget of $700M for SDA. The
                NDIS will be making payments to these housing providers
                (investors) that covers the costs incurred and provides a return
                on investment of over 4 - 5 times that of the average investment
                property.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="secret_properties">
        <div className="container width-less">
          <div className="row">
            <h3 align="center">
              Why should this be
              <br /> of interest to You?
            </h3>
            <div className="col-sm-6 col-md-5">
              <p>
                There are currently over 6,000 young people with a disability
                living in aged care facilities. Many more are living with
                Parents or in hospitals.
              </p>
              <p>
                There are now close to 16,000 people with a disability Australia
                wide on waiting lists for suitable accommodation. Most of the
                current and potential SDA residents are under the age of 45.{" "}
              </p>
              <p>
                Greater equality and options are needed as the current stock is
                not suitable or inappropriate.
              </p>
            </div>
            <div className="col-sm-6 col-md-7">
              <img className="w80p" src={ur_interest} />
            </div>
          </div>
        </div>
      </section>

      <section
        className="secret_properties"
        style={{background: '#f7f4f2', padding: '80px 0px', marginBottom: '0px'}}
      >
        <div className="container">
          <div className="row">
            <h3 align="center">
              How can this Help Me as
              <br /> an Investor?
            </h3>
            <div className="col-sm-6 left">
              <img
                className="w100p"
                src={howcan_thishelp}
              />
            </div>
            <div className="col-sm-6 right">
              <h6>Your income is received in 3 separate payments:</h6>
              <br />
              <ul>
                <li>
                  1. Reasonable Rent Contribution - based on 25% of the
                  Disability Support Pension
                </li>
                <li>2. SDA Accommodation Payment</li>
                <li>3. Commonwealth Rent Assistance</li>
              </ul>
              <br />
              <p>
                Rental income ranges from $84,000 - $130,000 p.a. based on
                location and type of accommodation provided.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="secret_properties"
        style={{background: '#652f73', padding: '80px 0px', marginTop: '0px'}}>
        <div className="container" style={{color: '#fff'}}>
          <div className="row">
            <h3 align="center" style={{color: '#93c121'}}>
              Show Me how it works?
            </h3>
            <div className="col-sm-6 left">
              <img className="w100p" src={showme} />
            </div>
            <div className="col-sm-6 right">
              <p style={{color: '#fff'}}>
                A typical home is normally 3 – 4 bedrooms, housing 2 – 3 people
                with a disability and one Career.
              </p>
              <p style={{color: '#fff'}}>
                Different home designs are offered to suit tenants requirements.
                From basic care to full spinal support designs.
              </p>
              <p style={{color: '#fff'}}>
                These layouts provide tenants a place to live independently,
                with people their own age and still receive the care they need.
              </p>
              <br />
              <p style={{color: '#fff'}}>
                <b>Recent Example</b>
              </p>
              <ul>
                <li>4 Bedroom Home - Robust Design - (2 tenants, 1 Career).</li>
                <li>House and land Package Price - $680,000</li>
                <li>
                  Income for this property p.a. based on 2 tenants - $89,280 or
                  $1717 p.w.
                </li>
                <li>
                  Based on this example, the investor will receive a gross
                  return p.a. of 13.12%.
                </li>
              </ul>

              <p style={{color: '#fff'}}>
                Rental income ranges from $84,000 - $130,000 p.a. based on
                location and type of accommodation provided.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="get_free_estimate get_free_estimate_new text-center">
        <div className="container">
          <div className="in_box" style={{background: '#f2f5f9'}}>
            <div className="top">
              <h5>Get in touch with us to learn more</h5>
              <a href="#" className="btn btn-black">
                Contact Us Now
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ndis;
