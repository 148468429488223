import React, { useEffect, useState } from "react";
import about_img_3 from "../../images/about_img_3.jpg";

const PrivacyPolicy = () => {
  return (
    <>
      <div id="About" className="section wf-section">
        <div className="container">
          <div>
            <h2>Privacy Policy</h2>

            <p>
              Galaxzi Pty Ltd Trading as &ldquo;Magicbricks&rdquo; Privacy
              Policy make it easier for you to understand what information we
              collect and why we collect it.&nbsp; <br />
              This Privacy Policy governs the manner in which Galaxzi Pty Ltd
              trading as &ldquo;Magicbricks&rdquo; collects, uses, maintains and
              discloses information collected from users (each, a
              &quot;User&quot;) of the www.magicbricks.com.au website
              (&quot;Site&quot;). This privacy policy applies to the Site and
              all products and services offered by Magicbricks .
            </p>

            <p>
              <br />
              <strong>Personal identification information</strong>
              <br />
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, register on the site, subscribe to the newsletter, fill
              out a form, and in connection with other activities, services,
              features or resources we make available on our Site. Users may be
              asked for, as appropriate, name, email address, mailing address,
              property address, phone number etc. Users may, however, visit our
              Site anonymously. We will collect personal identification
              information from Users only if they voluntarily submit such
              information to us. Users can always refuse to supply personally
              identification information, except that it may prevent them from
              engaging in certain Site related activities.
            </p>

            <p>
              <br />
              <strong>Non-personal identification information</strong>
              <br />
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our Site,
              such as the operating system and the Internet service providers
              utilized and other similar information.
            </p>

            <p>
              <br />
              <strong>Web browser cookies</strong>
              <br />
              Our Site may use &quot;cookies&quot; to enhance User experience.
              User\'s web browser places cookies on their hard drive for
              record-keeping purposes and sometimes to track information about
              them. User may choose to set their web browser to refuse cookies,
              or to alert you when cookies are being sent. If they do so, note
              that some parts of the Site may not function properly.
            </p>

            <p>
              <br />
              <strong>How we use collected information</strong>
              <br />
              Magicbricks collects and uses Users personal information for the
              following purposes:
              <br />
              - To improve our Site
              <br />
              We continually strive to improve our website offerings based on
              the information and feedback we receive from you.
              <br />
              - To improve customer service
              <br />
              Your information helps us to more effectively respond to your
              customer service requests and support needs.
              <br />
              - To send periodic emails
              <br />
              The email address Users provide for order processing, will only be
              used to send them information and updates pertaining to their
              order. It may also be used to respond to their inquiries, and/or
              other requests or questions. If User decides to opt-in to our
              mailing list, they will receive emails that may include company
              news, updates, related product or service information, etc. If at
              any time the User would like to unsubscribe from receiving future
              emails, we include detailed unsubscribe instructions at the bottom
              of each email or User may contact us via our Site.
            </p>

            <p>
              <br />
              <strong>How we protect your information</strong>
              <br />
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>

            <p>
              <br />
              <strong>
                What information do we collect from the Advertisers?
              </strong>
              &nbsp;
              <br />
              When customers fill any leads form to use our services or uses
              Magicbricks website, we collect Information such as the customer's
              name, email address, address, telephone number, property address
              and any other Information the customers may choose to provide to
              us on the platform.&nbsp;
            </p>

            <p>
              <br />
              <strong>Sharing your personal information</strong>
              <br />
              We do not sell, trade, or rent Users personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the purposes outlined above. <br />
              We may also disclose your information to third parties when we
              reasonably believe we are obligated to do so by law, and in order
              to investigate, prevent, or take action regarding suspected or
              actual prohibited activities, including but not limited to fraud
              and situations involving potential threats to the physical safety
              of any person.<strong>&nbsp;</strong>
              <br />
              We may transfer information to a successor entity in case of a
              corporate merger, consolidation, sale of assets or other corporate
              changes.
              <br />
              If you choose to sell property privately we do share some of your
              information to prospective buyers which is your name, phone,
              email, property address.{" "}
            </p>

            <p>
              <br />
              <strong>
                How long do we retain the information provided by the
                Advertisers?
              </strong>
              &nbsp;
              <br />
              If you create an account on our website, we will retain your
              information for as long as your account is active with us or for
              as long as you are receiving our services. After you have closed
              your account with us or ceased to use our services, we may retain
              and use your information as necessary to comply with our legal
              obligations, resolve disputes, and enforce our agreements.&nbsp;
            </p>

            <p>
              <br />
              <strong>Third party websites</strong>
              <br />
              Users may find advertising or other content on our Site that link
              to the sites and services of our partners, suppliers, advertisers,
              sponsors, licensors and other third parties. We do not control the
              content or links that appear on these sites and are not
              responsible for the practices employed by websites linked to or
              from our Site. In addition, these sites or services, including
              their content and links, may be constantly changing. These sites
              and services may have their own privacy policies and customer
              service policies. Browsing and interaction on any other website,
              including websites which have a link to our Site, is subject to
              that website\'s own terms and policies.
            </p>

            <p>
              <br />
              <strong>Advertising</strong>
              <br />
              Ads appearing on our site may be delivered to Users by advertising
              partners, who may set cookies. These cookies allow the ad server
              to recognize your computer each time they send you an online
              advertisement to compile non personal identification information
              about you or others who use your computer. This information allows
              ad networks to, among other things, deliver targeted
              advertisements that they believe will be of most interest to you.
              This privacy policy does not cover the use of cookies by any
              advertisers.
            </p>

            <p>
              <br />
              <strong>Changes to this privacy policy</strong>
              <br />
              Magicbricks has the discretion to update this privacy policy at
              any time. When we do, we will revise the updated date at the
              bottom of this page. We encourage Users to frequently check this
              page for any changes to stay informed about how we are helping to
              protect the personal information we collect. You acknowledge and
              agree that it is your responsibility to review this privacy policy
              periodically and become aware of modifications.
            </p>

            <p>
              <br />
              <strong>Your acceptance of these terms</strong>
              <br />
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>

            <p>
              <br />
              <strong>Contacting us</strong>
              <br />
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              hello @ magicbricks.com .au
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
