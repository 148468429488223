import React, { useEffect, useState } from "react";
import news_banner from "../../images/news_banner.jpg";
import pechanga_st_tarneit from "../../images/news/1685945007_15_pechanga_st_tarneit.png";
import news_2 from "../../images/news/1685838067_2.png";
import news_3 from "../../images/news/1685155755_5.png";
import news_4 from "../../images/news/1685001430_lead(67)(1).png";
import { Link } from "react-router-dom";
import EstimateForm from "../estimate-form";

const Articles = ({ setClassName }) => {

  const articles = [
    {
      title: "Unleashing the Power of Property Staging: Why it Matters and the Benefits You Can't Ignore",
      info: "Selling a property can be an exciting journey, but it comes with its fair share of challenges. In a competitive real estate market, how can you ensure your property stands out from the crowd and captu...",
      img: news_2, 
     },
     {
      title: "How to Find the Right Buyer for Your Property in Tarneit",
      info: "Selling a property in Tarneit can be an exhilarating journey, but finding the perfect buyer to appreciate and value your property is the real challenge. Are you eager to discover the secrets to attrac...",
      img: news_3, 
     },
     {
      title: "Understanding the Reasons Behind Unsold Properties in the Australian Real Estate Market",
      info: "Discover the key factors behind the unsold properties in the Australian real estate market and gain valuable insights to increase your chances of a successful sale. Explore pricing strategies, propert...",
      img: news_4, 
     },
 ]

  useEffect(() => {
    setClassName("sell_screen local-news");
    return () => {
      setClassName("");
    };
  }, []);
  return (
    <>
      <div
        class="components-section wf-section sold_banner"
        style={{ background: `url(${news_banner})` }}
      >
        <div class="container">
          <div class="grid">
            <div
              id="w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-77d4702e"
              class="column-large text-center"
            >
              <h1 class="margin-bottom-0">Local news and articles</h1>
              <p>
                We will keep you informed on local news and property market
                updates.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section class="local_news">
        <div class="container">
          <div class="local_news_top">
            <div class="news_img">
              <a href="#">
                <img src={pechanga_st_tarneit} />
              </a>
            </div>
            <div class="contant_box">
              <h3>
                <a href="#">
                  Buyer Journey to 15 Pechanga St Tarneit - Prepare to Be
                  Enchanted!
                </a>
              </h3>
              <div class="tas_line">
                <span>
                  <i class="fa-solid fa-camera-retro"></i> Posted by{" "}
                  <u>Magicbricks Admin</u> | <u>Real Estate</u>
                </span>
              </div>
              <p>
                We had an exclusive interview with the property buyer of 15
                Pechanga Street, Tarneit, who recently acquired this stunning
                property. Learn about their reasons for buying, what motivated
                them to make the purchase, their search for the perfect home
                (how they found it), and what made them fall in love with this
                property. You can learn from their experience and get ideas for
                your own home-buying jour...
              </p>
              <div class="bottom-button">
                <a href="#">
                  <button class="btn-reading">Continue Reading</button>
                </a>
              </div>
            </div>
          </div>
          <div class="news_listing">
          {articles.map((article, index) => ( 
            <div class="local_news_top">
              <div class="row">
                <div class="col-sm-4">
                  <div class="news_img">
                    <a href="#">
                      <img src={article?.img} />
                    </a>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="contant_box">
                    <h3>
                      <Link to="#">
                      {article?.title}
                      </Link>
                    </h3>
                    <div class="tas_line">
                      <span>
                        <i class="fa-solid fa-camera-retro"></i> Posted by{" "}
                        <u>Magicbricks Admin</u> | <u>Real Estate</u>
                      </span>
                    </div>
                    <p>
                    {article?.info}
                    </p>
                    <div class="bottom-button">
                      <Link to="#">
                        <button class="btn-reading">Continue Reading</button>
                      </Link>
                      <button class="share">
                        <i class="fa-solid fa-share"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ))}

          </div>
        </div>
      </section>

      <EstimateForm />
    </>
  );
};

export default Articles;
