import React, { useEffect, useState } from "react";
import agent01 from "../../images/profile/1684134943_agent868.jpg";
import agent02 from "../../images/profile/1688883906_jass_photo_1.png";
import agent03 from "../../images/profile/1686977307_kolipic.jpg";
import agent04 from "../..//images/profile/1688883834_main1.png";



import { Link } from "react-router-dom";

const Team = ({setClassName}) => {
  const users = [
    {
      userimg: agent01,
      name: "Prit Pal",  
      posting:  "Sales Executive",
      phone: "0424 415 110",
      email: "Email Prit Pal ",
    },
    {
      userimg: agent02,
      name: "Jass Kaur",  
      posting:  "Property Manager",
      phone: "03 8353 2119",
      email: "Email Jass Kaur",
    },
    {
      userimg: agent03,
      name: "G. Koli",  
      posting:  "Property Sales Partner",
      phone: "0417 335 750",
      email: "Email G. Koli",
    },
    {
      userimg: agent04,
      name: "Kirpal Tejay",  
      posting:  "Property Sales Partner",
      phone: "0412 271 035",
      email: "Email Kirpal Tejay",
    }

  ]
  useEffect(()=>{
    setClassName("our_team");
    return()=>{
      setClassName("");
    }
  },[])
  return (
    <>
    <div className="our_team">
      <section class="our_team_section">
        <div class="container">
          <h4>Meet our agents</h4>

          <div class="row">
            {users.map((item, index) => { 
              return(
                <>
            <div class="col-sm-4">
              <div class="our_team_box">
                <a href="#">
                  <img class="agents_img" src={item?.userimg} />
                </a>

                <h5>{item?.title}</h5>

                <p>{item?.posting}</p>

                <p>
                  <b>{item?.phone}</b>
                </p>

                <p>
                  <Link to="#">{item?.email}</Link>
                </p>
              </div>
            </div>
            </>
            )
            })}
          </div>
        </div>
      </section>
      </div>
    </>
  );
};

export default Team;
