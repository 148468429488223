import React, { useEffect, useState } from 'react';
import magicbrickslogo from "../../images/magicbricks-logo.svg";
import logoicon from "../../images/logo_icon.png";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

const Header = () => {

  // dropdown auto close 
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownToggle = (event, isOpen) => {
    setIsOpen(isOpen);
  };
  const handleLinkClick = () => {
    setIsOpen(false); // Manually close the dropdown when a link is clicked.
  };
    // dropdown auto close end

   
  // toggle button
  const [isClassAdded, setIsClassAdded] = useState(false);
  const toggleClass = () => {
    setIsClassAdded(!isClassAdded);
  };
  // toggle button end
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scroll = window.pageYOffset || document.documentElement.scrollTop;
        setIsFixed(scroll >= 60);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    <>
      <nav  className={isFixed ? 'active navbar' : 'navbar'} style={{ background : 'rgba(255, 255, 255, 0)'}} data-w-id="4238f5d6-86e9-2765-4953-3ac963544333">
        <div className="top_navi_bar">
          <div className="container">
            <ul className="top_navi">
              <li>
                <a href="#">
                  Build your home
                </a>
              </li>
              <li>
                <a href="#">
                  Build a custom home
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="clear"></div>

        <div className="container-fluid">
          <div className="navbar-wrap w100p">
            <Link to="/" className="brand w-inline-block">
              <img
                width="150px" style={{marginLeft: "15px"}}
                src={magicbrickslogo}
               
                loading="lazy"
                alt=""
                className="brand-image"
              />
            </Link>

            <ul className="main-menu">
              <li>
              <Link to="/properties-for-sale">Buy</Link> 
              </li>

              <li>
                <Link to="/sell-your-property">Sell</Link>
              </li>

              <li>
                <Link to="/home-and-land">Home &amp; Land </Link>
              </li>

              <li>
                <Link to="/sold">Sold</Link>
              </li>

              <li>
                <Link to="/estates">Estates</Link>
              </li>

              <li>
                <Link to="/articles">Local news</Link>
              </li>
              <li>
                <Link to="/sell">Sellers guide</Link>
              </li>
              <Dropdown as="li" className="drop-item" show={isOpen} onToggle={handleDropdownToggle}>
      <Dropdown.Toggle as="a" href="#" id="dropdown-basic">
        About
      </Dropdown.Toggle>
      <Dropdown.Menu className="drop-menu">
        <li>
          <Link to="/about-us" onClick={handleLinkClick}>
            About us
          </Link>
        </li>
        <li>
          <Link to="/team" onClick={handleLinkClick}>
            Our team
          </Link>
        </li>
        <li>
          <Link to="/join-our-team" onClick={handleLinkClick}>
            Join Our Team
          </Link>
        </li>
        <li>
          <Link to="/contact-us" onClick={handleLinkClick}>
            Contact us
          </Link>
        </li>
      </Dropdown.Menu>
    </Dropdown>
              {/* <Dropdown as="li" className="drop-item"> 
              <Dropdown.Toggle as="a" href='#'>About</Dropdown.Toggle>
                <Dropdown.Menu className="drop-menu"> 
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/team">Our team</Link>
                  </li>
                  <li>
                    <Link to="/join-our-team">Join Our Team</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li> 
                </Dropdown.Menu>
              </Dropdown> */}
              {/* <!-- <li><a href="<?php echo URL?>join-our-team">Join our Team</a></li> -->

           <!-- <li><a href="<?php echo URL?>contact-us">Contact</a></li>--> */}
            </ul>

            <div onClick={toggleClass}
              data-w-id="4238f5d6-86e9-2765-4953-3ac963544338"
              className={isClassAdded ? 'navbar-icon active' : 'navbar-icon'}
            >
              <div className="navbar-icon-bar bar-1"></div>

              <div className="navbar-icon-bar bar-2"></div>

              <div className="navbar-icon-bar bar-3"></div>
            </div>
          </div>

          <div className={isClassAdded ? 'navbar-menu main' : 'navbar-menu'}>
            <div className="navbar-menu-wrap">
              <div className="navbar-menu-links-wrap">
                <div className="w100p text-center">
                  <img width={60}
                    src={logoicon}
                    
                    alt=""
                  />
                </div>
                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-1-left"></div>

                  <Link onClick={toggleClass} to="/properties-for-sale"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-1">Buy</div>
                    </div>
                  </Link>

                  <div className="navbar-menu-line line-1-right"></div>
                </div>

                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-2-left"></div>

                  <a onClick={toggleClass}
                    href="#"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-2"> Sell </div>
                    </div>
                  </a>

                  <div className="navbar-menu-line line-2-right"></div>
                </div>

                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-2-left"></div>

                  <Link onClick={toggleClass}
                    to="/home-and-land"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-2">
                        {" "}
                        Home and Land{" "}
                      </div>
                    </div>
                  </Link>

                  <div className="navbar-menu-line line-2-right"></div>
                </div>

                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-3-left"></div>

                  <Link onClick={toggleClass}
                    to="/sold"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-3">Sold</div>
                    </div>
                  </Link>

                  <div className="navbar-menu-line line-3-right"></div>
                </div>

                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-3-left"></div>

                  <Link onClick={toggleClass}
                    to="/estates"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-3">Estates</div>
                    </div>
                  </Link>

                  <div className="navbar-menu-line line-3-right"></div>
                </div>

                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-3-left"></div>
                  <a onClick={toggleClass} href="#" className="navbar-menu-link w-inline-block">
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-3">
                        Build Your Home
                      </div>
                    </div>
                  </a>
                  <div className="navbar-menu-line line-3-right"></div>
                </div>
                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-3-left"></div>
                  <Link onClick={toggleClass} to="/sell"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-3">
                        Sellers Guide
                      </div>
                    </div>
                  </Link>
                  <div className="navbar-menu-line line-3-right"></div>
                </div>
                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-3-left"></div>
                  <a onClick={toggleClass}
                    href="#"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-3">
                        Land For Sale
                      </div>
                    </div>
                  </a>
                  <div className="navbar-menu-line line-3-right"></div>
                </div>
                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-3-left"></div>
                  <Link onClick={toggleClass}
                    to="/join-our-team"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-3">
                        Join Our Team
                      </div>
                    </div>
                  </Link>
                  <div className="navbar-menu-line line-3-right"></div>
                </div>
                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-3-left"></div>
                  <Link onClick={toggleClass} to="/sell-your-property"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-3">
                        Request Free Appraisal
                      </div>
                    </div>
                  </Link>
                  <div className="navbar-menu-line line-3-right"></div>
                </div>

                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-4-left"></div>

                  <Link onClick={toggleClass}
                    to="/articles"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-4">Local news</div>
                    </div>
                  </Link>

                  <div className="navbar-menu-line line-4-right"></div>
                </div>

                <div className="navbar-menu-link-row">
                  <div className="navbar-menu-line line-5-left"></div>

                  <Link onClick={toggleClass} to="/about-us" 
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-5">About</div>
                    </div>
                  </Link>

                  <div className="navbar-menu-line line-5-right"></div>
                </div>

                <div className="navbar-menu-link-row last-item">
                  <div className="navbar-menu-line line-7-left"></div>

                  <Link onClick={toggleClass} to="/contact-us"
                    className="navbar-menu-link w-inline-block"
                  >
                    <div className="navbar-menu-link-mask">
                      <div className="navbar-menu-link-text item-7">Contact</div>
                    </div>
                  </Link>

                  <div className="navbar-menu-line line-7-right"></div>
                </div>
              </div>

              <div className="grid-thirds navbar-menu-bottom">
                <div
                  id="w-node-_4238f5d6-86e9-2765-4953-3ac963544372-63544333"
                  className="navbar-menu-bottom-text first-item"
                >
                  <strong>&copy; Magicbricks Pty Ltd</strong>
                </div>

                <div
                  id="w-node-_4238f5d6-86e9-2765-4953-3ac963544375-63544333"
                  className="navbar-menu-social"
                >
                  <a onClick={toggleClass}
                    href="https://www.instagram.com/magicbricksau/"
                    className="navbar-menu-social-link w-inline-block"
                    target="_blank"
                  >
                    <img
                      src="<?php echo URL?>images/Icon-instagram-sec1.svg"
                      loading="lazy"
                      alt=""
                      className="social-icon"
                    />
                  </a>

                  <a onClick={toggleClass}
                    href="https://www.facebook.com/magicbricks.com.au"
                    className="navbar-menu-social-link w-inline-block"
                    target="_blank"
                  >
                    <img
                      src="<?php echo URL?>images/Icon-facebook-sec1.svg"
                      loading="lazy"
                      alt=""
                      className="social-icon"
                    />
                  </a>
                </div>
                <div
                  id="w-node-_4238f5d6-86e9-2765-4953-3ac96354437c-63544333"
                  className="navbar-menu-bottom-text last-item"
                >
                  <strong>03 8353 2119</strong>
                </div>
              </div>

              <div className="navbar-menu-bg"></div>
            </div>

            <div className="navbar-menu-photo-mask">
              <div className="navbar-menu-photo"></div>
            </div>
          </div>
        </div>
      </nav>

      {/* <!--   <div style="clear:both"></div>
    <div style="height:40px"></div>--> */}
    </>
  );
};

export default Header;
