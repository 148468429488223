import React, { useEffect, useState } from 'react';
import footer_logo from '../../images/footer_logo.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

const Footer = () => {
  // toggle button
  const [isClassNameAdded, setIsClassNameAdded] = useState(false); 
  const toggleClassName = () => {
    setIsClassNameAdded(!isClassNameAdded);
  };
  
  // toggle button end
 
  return (
    <> 
         <div className="section footer wf-section">
      
      <div className="container">
        <div className="grid margin-top-80 mt-0">
          <img className="footer_logo" src={footer_logo} />
         
          <div id="w-node-ead02fe9-42d7-65d9-c635-bfe6cc50220b-cc5021fb" className="footer_nav flex-stretch-space footer-template-links-wrap">
            <Link to="/properties-for-sale" className="footer-link first-item">Buy</Link>
            <Link to="/sell-your-property" className="footer-link">Sell</Link> 
            <a href="#" className="footer-link">Sold</a> 
            <Link to="/properties-for-sale" className="footer-link">Residential</Link> 
            <Link to="/properties-for-sale" className="footer-link">Commercial</Link> 
            <Link to="/properties-for-sale" className="footer-link">Land for sale</Link> 
            <Link to="/properties-for-sale" className="footer-link">Sell Property</Link> 
            <Link to="/properties-for-sale"  className="footer-link">Request Free Appraisal</Link> 
           
          </div>
          <div className="mt0 footer_nav flex-stretch-space footer-template-links-wrap mt-5 margin-top-16">
            <Link to="/about-us" className="footer-link first-item">About us</Link>
            
            <Link to="/team" className="footer-link">Our Team</Link> 
            <Link to="/join-our-team" className="footer-link">Join our Team</Link>  
            <Link to="/articles" className="footer-link">Articles</Link>  
             <Link to="/ndis" className="footer-link">Ndis</Link> 
            <Link to="/terms" className="footer-link">Terms &amp; Conditions</Link>  
            <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>  
            <Link to="/contact-us" className="footer-link">Contact us</Link>  
            <Link to="/properties-for-sale" className="footer-link">Victoria buyers</Link>  
            <a href="https://www.consumer.vic.gov.au/duediligencechecklist" target="_blank" className="footer-link">Due diligence</a>  
          </div>                                                                          
        </div>
      </div>
      <div className="full-container">
        <div className="grid-thirds footer-bottom">
          <div id="w-node-ead02fe9-42d7-65d9-c635-bfe6cc502218-cc5021fb" style={{'justifyContent': 'start'}} className="flex-stretch-space">
            <a href="https://www.instagram.com/magicbricksau/ " target="_blank" className="social-link first-item">Instagram</a>
            <a href="https://www.facebook.com/magicbricks.com.au" target="_blank" className="social-link">Facebook</a>
            
          </div>
          
          <a id="w-node-ead02fe9-42d7-65d9-c635-bfe6cc50221a-cc5021fb"  className="template-link">© 2023 Magicbricks Pty Ltd</a>
          <a  id="w-node-ead02fe9-42d7-65d9-c635-bfe6cc50221c-cc5021fb" href="https://salesboss.ai" target="_blank" className="template-link">Real Estate Websites by: <b>SalesBoss</b></a>

        </div>
        <p style={{color: '#999', 'textAlign': 'center', 'paddingTop': '40px', 'fontSize' : '12px'}}>Disclaimer: Magicbricks is an independently owned and operated business in Australia and is not affiliated with, sponsored by, or associated with any other company or organization.</p>
      </div>
    </div> 

<footer className="mobile_footer">
  <ul className="footer_navi">
   
  <Dropdown as="li" className='more_menu '>
      <Dropdown.Toggle as="a" variant="success" id="dropdown-basic">
      <i class="fa-solid fa-cart-shopping"></i><br/> Buy
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
      <li><Dropdown.Item href="#">House and Land Packages</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Land For Sale</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Properties For Sale</Dropdown.Item></li>
      </Dropdown.Menu>
    </Dropdown>
    <Dropdown as="li" className='more_menu '>
      <Dropdown.Toggle as="a" variant="success" id="dropdown-basic">
      <i className="fa-solid fa-hand-holding-dollar"></i> <br/> Sell 
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
      <li><Dropdown.Item href="#">Sellers Guide</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Request an Appraisal</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Recently Sold</Dropdown.Item></li>
      </Dropdown.Menu>
    </Dropdown>
    <Dropdown as="li" className='more_menu '>
      <Dropdown.Toggle as="a" variant="success" id="dropdown-basic">
      <i className="fa-solid fa-house"></i> <br/>  Rent 
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
      <li><Dropdown.Item href="#">Properties For Rent</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Request a Free Rental Appraisal</Dropdown.Item></li>
      </Dropdown.Menu>
    </Dropdown>
    <Dropdown as="li" className='more_menu '>
      <Dropdown.Toggle as="a" variant="success" id="dropdown-basic">
      <i class="fa-solid fa-building"></i> <br/>  Build 
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
      <li><Dropdown.Item href="#">Build Custom Homes</Dropdown.Item></li>
      <li><Dropdown.Item href="#">New Home Construction</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Home Build Process</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Home Designs</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Build NDIS Homes</Dropdown.Item></li>
      </Dropdown.Menu>
    </Dropdown>
    <Dropdown as="li" className='more_menu '>
      <Dropdown.Toggle as="a" variant="success" id="dropdown-basic">
      <i className="fa-solid fa-circle-ellipsis"></i> <br/>More
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
      <li><Dropdown.Item href="#">About</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Contact Us</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Our Team</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Join Our Team</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Local News</Dropdown.Item></li>
      <li><Dropdown.Item href="#">Estates</Dropdown.Item></li>
      </Dropdown.Menu>
    </Dropdown>
    
    
     
    {/* <li className={isClassNameAdded ? 'more_menu open' : 'more_menu'}> 
    <Link to='#' onClick={toggleClassName}><i className="fa-solid fa-circle-ellipsis"></i> <br/>More</Link>
      <ul>
      	<li><a href="#"><i className="fa-solid fa-house-circle-check"></i> <br/>Sold</a></li> 
        <li><a href="#">Commercial</a></li>
        <li><Link to="/about-us">About</Link></li>
        <li><a href="#">Join our Team</a></li>
        <li><Link to="/contact-us">Contact</Link></li>
      </ul>
    </li> */}
  </ul>
</footer>
        
    </>
  );
};

export default Footer;
