import React, { useEffect, useState } from "react";
import tarneit_map2 from "../../images/estates/1686211243_grand_central_estate_-_tarneit_map2.png";
import tarneit_map3 from "../../images/estates/1686211966_wattle_park_aerial.jpg";
import p_logo from "../../images/devlogo/1686124250_p_logo.png";
import EstimateForm from "../estimate-form";
const Estates = ({ setClassName }) => {
  const estates = [
      {
        img: tarneit_map2,
        title: "Stockland Wattle Park - Tarneit",
        logo: p_logo
      },
      {
        img: tarneit_map3,
        title: "Stockland Wattle Park - Tarneit",
        logo: p_logo
      }
  ]

  useEffect(() => {
    setClassName("property_listing_screen estates_page");
    return () => {
      setClassName("");
    };
  }, []);
  return (
    <>
      <div
        id="Listing-2"
        class="listing_screen section padding-0 wf-section"
        style={{marginBottom: '100px'}}
      >
        <div class="listing-2-filter" style={{position: 'fixed', zIndex: '999'}}>
          <div class="container-fluid">
            <div class="listing-2-filter-holder estates_screen">
              <form action="" method="GET" id="frmSearch" name="frmSearch">
                <div class="list_filter_box">
                  <div class="d-flex">
                    <select
                      class="form-control"
                      name="location"
                    >
                      <option value="">All Locations</option>
                    </select>
                  </div>
                  <div class="d-flex">
                    <select
                      name="cmbDeveloper"
                      id="cmbDeveloper"
                      class="form-control"
                    >
                      <option value="">All Developers</option>c
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="gray_info_box">
        <div class="container">
          <p style={{fontSize: '16px'}}>
            We work in collaboration with major land developers to source the
            most desirable land options for our clients. Through our strong
            partnerships, we have access to a wide range of land opportunities
            that meet client preferences and budgets.
          </p>
          <p  style={{fontSize: '16px'}}>
            Our network allows us to present our clients with exclusive choices
            and ensure that they have access to prime locations for building
            their dream homes.
          </p>
        </div>
      </div>

      <div class="estates_in_tarneit">
        <div class="container">
          <h5>All Estates</h5>
          {estates.map((estate) => (
          <div class="estates_in_tarneit_box">
            <a href="#">
              <img src={estate?.img} />
            </a>
            <div class="estates_in_tarneit_info">
           {estate?.title}
              <img
                src={estate?.logo}
                class="right_logo"
              />
            </div>
          </div>
          ))}


        </div>
      </div>
      <EstimateForm />
    </>
  );
}; 

export default Estates;
