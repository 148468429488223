import React, { useEffect, useState } from "react";
import about_img_3 from "../../images/about_img_3.jpg";
import about_img_2 from "../../images/about_img_2.jpg";
import about_01 from "../../images/about_01.jpg";
import About_thumb04 from "../../images/About-thumb04.jpg";
import About_thumb03 from "../../images/About-thumb03.jpg";
import About_thumb02 from "../../images/About-thumb02.jpg";
import About_thumb01 from "../../images/About-thumb01.jpg";

const AboutUs = ({setClassName}) => {
  useEffect(()=>{
    setClassName("about_us");
    return()=>{
      setClassName("");
    }
  },[])
  return (
    <>
      <div className="about_banner padding-bottom-0 wf-section">
        <div className="container">
          <h6 className="text-center w100p">About Us</h6>
          <div className="grid spacer-80">
            <div>
              <div className="about-hero-line">
                <div>Find</div>
                <img
                  src={About_thumb01}
                  loading="eager"
                  alt=""
                  className="about-hero-image"
                />
                <div>
                  your <span>dream</span>
                </div>
              </div>
              <div className="about-hero-line">
                <img
                  src={About_thumb02}
                  loading="eager"
                  alt=""
                  className="about-hero-image"
                />
                <div>
                  <span className="color-secondary-1">home, </span> Sell your home,
                </div>
              </div>
              <div className="about-hero-line">
                <img
                  src={About_thumb03}
                  loading="eager"
                  alt=""
                  className="about-hero-image"
                />
                <div>Build your home</div>
              </div>
              <div className="about-hero-line">
                <div>
                  <span className="color-secondary-1">with</span>{" "}
                </div>
                <img src={About_thumb04} className="about-hero-image" />
                <div>magicbricks</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="about_info">
        <div className="container">
          <div className="about_info_box">
            <div className="row align-items-center">
              <div className="col-sm-6 order-2 text-right">
                <img src={about_01} />
              </div>
              <div className="col-sm-6 order-1">
                <h6>About us</h6>
                <h5>
                  We are a Real Estate Agency with a strong vision on giving an
                  amazing customer service.
                </h5>
                <p>
                  Our passion for the work we do is evident in everything we do.{" "}
                </p>
                <p>
                  Our team of experienced and knowledgeable real estate agents
                  is dedicated to helping clients achieve their real estate
                  goals, and we take great pride in our ability to make a
                  positive impact in the lives of those we serve.
                </p>
                <div className="d-flex heading_button">
                  <h5>Let’s get in touch Today </h5>
                  <a href="#" className="button-cta w-inline-block button-black">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="about_info_box">
            <div className="row align-items-center">
              <div className="col-sm-6 order-1 text-left">
                <img src={about_img_2} />
              </div>
              <div className="col-sm-6 order-2">
                <h6>At Magicbricks, families are our top priority.</h6>
                <p>
                  At Magicbricks, families are our top priority. We understand
                  that buying, selling, or building a home is one of the biggest
                  decisions that a family can make, and we are dedicated to
                  making the process as smooth and stress-free as possible.{" "}
                </p>
                <p>
                  What makes Magicbricks unique is our extraordinary team of
                  individuals who put their passion, dedication, and expertise
                  into taking care of our clients houses. We love what we do and
                  we are motivated to deliver an outstanding level of service.
                </p>
              </div>
            </div>
          </div>

          <div className="about_info_box">
            <div className="row align-items-center">
              <div className="col-sm-6 order-2 text-right">
                <img src={about_img_3} />
              </div>
              <div className="col-sm-6 order-1">
                <h5>
                  We like to exceed our clients expectations delivering amazing
                  deals just for them.
                </h5>

                <p>
                  We use the latest technology and innovative marketing
                  strategies to reach a wider audience of potential buyers and
                  sellers, and to always exceed our clients' expectations.{" "}
                </p>

                <p>
                  We also aim to provide our clients with access to a network of
                  trusted professionals, including mortgage brokers, lawyers,
                  and building inspectors, to help streamline the real estate
                  process and ensure the best possible results.
                </p>

                <div className="d-flex heading_button">
                  <h5>Let’s get in touch Today</h5>
                  <a href="#" className="button-cta w-inline-block button-black">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
