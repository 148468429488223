import React, { useEffect, useState } from "react";
import contact_us from "../../images/contact_us.jpg";

const ContactUs = () => {
  const [thanks, setThanks] = useState(false);

  useEffect(() => {
    // Parse the query parameters to check if thanks=1 is present
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('thanks') === '1') {
      setThanks(true);
    }
  }, []);
  return (
    <> 
    <div className="contact_page">
      <div id="Contact-Form-2-Section" class="wf-section">
        <div class="container">
          <div class="row">
            <div class="col-sm-7">
              <div class="contact-form-21 w-form">
                <form
                  id="frmApp"
                  name="frmApp"
                  data-name="Email Form"
                  method="get"
                  class="grid spacer-24"
                >
                  <h6>Contact us</h6>

                  <h5>Get in touch with us</h5>

                  <div class="row">
                    <div class="col-sm-6">
                      <p>
                        Suite 653, 585 Little Collins St, <br />
                        Melbourne VIC 3000
                      </p>
                    </div>

                    <div class="col-sm-6">
                      <p>
                        Phone: 03 8353 2119 <br />
                        Email: info@magicbricks.com.au
                      </p>
                    </div>
                  </div>

                  {thanks ? (
                      <div style={{ paddingBottom: '20px', color: '#090' }}>
                        Thank you for submitting the contact us form! We appreciate your interest in our services and will get back to you as soon as possible. Have a great day!
                      </div>
                    ) : (
                      <>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="General-enquiry" class="contact-label">
                          Enquiry type *
                        </label>

                        <select
                          class="contact-text-field-2 w-input"
                          name="cmbGeneral"
                          data-validation="required"
                          data-validation-error-msg="Select enquiry type"
                        >
                          <option value="" hidden>
                            Select Enquiry type
                          </option>

                          <option value="General Enquiry">
                            General Enquiry
                          </option>

                          <option value="Selling a property">
                            Selling a property
                          </option>

                          <option value="Buying a property">
                            Buying a property
                          </option>

                          <option value="Building a Home">
                            Building a Home
                          </option>

                          <option value="Become an agent">
                            Become an agent
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="firstname" class="contact-label">
                          First name *
                        </label>

                        <input
                          type="text"
                          class="contact-text-field-2 w-input"
                          maxlength="256"
                          name="txtFirstName"
                          id="txtFirstName"
                          data-validation="required"
                          data-validation-error-msg="First Name cannot be blank"
                          placeholder="First name"
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="lastname" class="contact-label">
                          Last name *
                        </label>

                        <input
                          type="text"
                          class="contact-text-field-2 w-input"
                          maxlength="256"
                          name="txtLastName"
                          data-validation="required"
                          data-validation-error-msg="Last name cannot be blank"
                          data-name="lastname"
                          placeholder="Last name"
                          id="lastname"
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="Email" class="contact-label">
                          Email address *{" "}
                        </label>

                        <input
                          type="email"
                          class="contact-text-field-2 w-input"
                          maxlength="256"
                          name="txtEmail"
                          data-validation="email required"
                          data-validation-error-msg="Please enter valid email"
                          data-name="Email"
                          placeholder="Enter your email"
                          id="Email"
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="Phone" class="contact-label">
                          Mobile number
                        </label>

                        <input
                          type="tel"
                          class="contact-text-field-2 w-input"
                          maxlength="10"
                          name="txtPhone"
                          id="txtPhone"
                          data-name="Phone"
                          placeholder="0400 000 000"
                        />
                      </div>
                    </div>

                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="Message" class="contact-label">
                          Message us *
                        </label>

                        <textarea
                          placeholder="How can we help?"
                          maxlength="5000"
                          id="txtMessage"
                          name="txtMessage"
                          data-name="Message"
                          class="contact-text-area-2 w-input"
                        ></textarea>

                        <div class="receive_alerts">
                          <input type="checkbox" name="ckOpt" value="1" />{" "}
                          Opt-in to receive latest property alerts & news
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12">
                      <div class="form-group">
                        <input
                          class="g-recaptcha"
                          data-validation="recaptcha"
                          data-validation-recaptcha-sitekey="6Lc38CkUAAAAAGSzBr9awm5tAfiMLHitD5f21vI4"
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    id="submitBtn"
                    name="submitBtn"
                    class="w-button btn-black"
                  >
                    Send
                  </button>
                  </>
                  )}
                 
                </form>

                <div class="error-message light-error w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-5">
              <img class="w100p" src={contact_us} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactUs;
