import React, { useEffect, useState } from "react";
import about_img_3 from "../../images/about_img_3.jpg";
import about_img_2 from "../../images/about_img_2.jpg";
import about_01 from "../../images/about_01.jpg";
import About_thumb04 from "../../images/About-thumb04.jpg";
import About_thumb03 from "../../images/About-thumb03.jpg";
import About_thumb02 from "../../images/About-thumb02.jpg";
import About_thumb01 from "../../images/About-thumb01.jpg";

const SellYourProperty = ({ setclassPropWrap }) => {
  useEffect(() => {
    setclassPropWrap("join_team_page");
    return () => {
      setclassPropWrap("");
    };
  }, []);
  return (
    <>
      <div className="appraisal-request estimate_screen">
        <div
          id="Contact-Form-2-Section"
          className="section wf-section careers_contact_form"
        >
          <div className="container">
            <div className="top">
              <h6>Sell your property</h6>
              <h2>
                Discover what your home is <br />
                worth in today’s market
              </h2>
            </div>
            <div className="banner_form">
              <div className="input_box banner_from">
                <form
                  action="#"
                  method="GET"
                  id="frmApp"
                >
                  <div className="input_box banner_from">
                    <input
                      type="text"
                      name="txtAddress"
                      className="form-control"
                      placeholder="Start typing your property address"
                      id="address-input"
                      autoComplete="off"
                    />
                    <input type="hidden" name="hdAdd" id="hdAdd" />
                    <input type="hidden" name="hdLong" id="hdLong" />
                    <input type="hidden" name="hdLat" id="hdLat" />

                    <button type="submit" className="btn btn-black">
                      What’s my property worth?
                    </button>
                  </div>
                </form>
              </div>
              <p>We know your home is personal, and we treat it that way</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellYourProperty;
