import React, { useEffect, useState } from "react";

import new_magicbricks_01 from "../../images/new_magicbricks_01.jpg"; 
import large_arrow_sec_1 from "../../images/large-arrow---sec-1.svg"; 
import EstimateForm from "../estimate-form";

const Sell = ({setClassName}) => {
  useEffect(()=>{
    setClassName("sell_screen");
    return()=>{
      setClassName("");
    }
  },[])
  return (
    <>
  
      <div class="components-section wf-section sold_banner">
        <div class="container">
          <div class="grid">
            <div
              id="w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-77d4702e"
              class="column-large text-center"
            >
              <div class="margin-top-24">Sell your home</div>

              <h1 class="margin-bottom-0">
                We know your home is personal, <br /> and we treat it that way
              </h1>

              <div class="banner_form">
                <div class="input_box banner_from">
                  <form
                    action="#"
                    method="GET"
                    id="frmApp"
                  >
                    <div class="input_box banner_from">
                      <input
                        type="text"
                        name="txtAddress"
                        class="form-control"
                        placeholder="Start typing your property address"
                        id="address-input"
                        autocomplete="off"
                      />

                      <input type="hidden" name="hdAdd" id="hdAdd" />

                      <input type="hidden" name="hdLong" id="hdLong" />

                      <input type="hidden" name="hdLat" id="hdLat" />

                      <button type="submit" class="btn btn-black">
                        What’s my property worth?
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="padding-bottom-0 wf-section guide_section">
        <div class="container">
          <h4 class="text-center">
            We guide people through Buying, Selling, Building, <br />
            and Styling so they can obtain the best <br />
            results from their Investment.
          </h4>

          <div class="grid-quarters">
            <div
              id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f73b-77d4702e"
              class="column-large"
            >
              <h4 class="margin-bottom-0">The process</h4>
            </div>

            <a
              id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f73e-77d4702e"
              href="#"
              class="button-secondary w-inline-block"
            >
              <div>
                Explore
                <br />
                Properties
              </div>
              <img
                src={large_arrow_sec_1}
                loading="eager"
                alt=""
                class="button-arrow"
              />
            </a>

            <div
              id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f744-77d4702e"
              class="margin-top-16"
            >
              <div class="stats-item">
                <h2
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f746-77d4702e"
                  class="display-3 margin-bottom-0"
                >
                  01
                </h2>

                <div
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f748-77d4702e"
                  class="column-medium"
                >
                  <div>
                    <strong>Determine the value of your property: </strong>
                    Before listing your property for sale, it's important to
                    have an accurate understanding of its value. You can hire a
                    licensed valuer or real estate agent to conduct a property
                    appraisal.{" "}
                  </div>
                </div>
              </div>

              <div class="stats-item">
                <h2
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f74c-77d4702e"
                  class="display-3 margin-bottom-0"
                >
                  02
                </h2>

                <div
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f74e-77d4702e"
                  class="column-medium"
                >
                  <div>
                    <strong>Prepare your property for sale</strong>: Make any
                    necessary repairs or renovations to increase the appeal of
                    your property. Make sure it is clean and well-maintained
                    before listing it for sale.{" "}
                  </div>
                </div>
              </div>

              <div class="stats-item">
                <h2
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f752-77d4702e"
                  class="display-3 margin-bottom-0"
                >
                  03
                </h2>

                <div
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f754-77d4702e"
                  class="column-medium"
                >
                  <div>
                    <strong>Choose a real estate agent</strong>: You can choose
                    to work with a real estate agent or sell your property
                    privately. If you choose to work with an agent, they will
                    help you with the process of selling your property,
                    including listing it, advertising it, and showing it to
                    potential buyers.{" "}
                  </div>
                </div>
              </div>

              <div class="stats-item">
                <h2
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f758-77d4702e"
                  class="display-3 margin-bottom-0"
                >
                  04
                </h2>

                <div
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f75a-77d4702e"
                  class="column-medium"
                >
                  <div>
                    <strong>List your property</strong>: Your real estate agent
                    will list your property on popular real estate websites,
                    such as realestate.com.au, domain.com.au, homely, and
                    include high-quality photos and detailed information about
                    the property. Your agent will also create marketing
                    materials of your property.{" "}
                  </div>
                </div>
              </div>

              <div class="stats-item last-item">
                <h2
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f75e-77d4702e"
                  class="display-3 margin-bottom-0"
                >
                  05
                </h2>

                <div
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f760-77d4702e"
                  class="column-medium"
                >
                  <div>
                    <strong>Home inspections</strong>: Your real estate agent
                    will schedule inspections for potential buyers to view your
                    property. They will also negotiate offers on your behalf and
                    help you navigate the legal process of selling your
                    property.{" "}
                  </div>
                </div>
              </div>

              <div class="stats-item last-item">
                <h2
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f75e-77d4702e"
                  class="display-3 margin-bottom-0"
                >
                  06
                </h2>

                <div
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f760-77d4702e"
                  class="column-medium"
                >
                  <div>
                    <strong>Close the sale: </strong> Once you have accepted an
                    offer, your real estate agent will help you navigate the
                    legal process of closing the sale. This includes working
                    with a lawyer or conveyancer to sign a sales contract,
                    transfer the title, and arrange for the transfer of funds.
                  </div>
                </div>
              </div>

              <div class="stats-item last-item">
                <h2
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f75e-77d4702e"
                  class="display-3 margin-bottom-0"
                >
                  07
                </h2>

                <div
                  id="w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f760-77d4702e"
                  class="column-medium"
                >
                  <div>
                    <strong>Finalize the sale</strong>: Once the sale is
                    finalized, you will receive the proceeds from the sale and
                    the buyer will take possession of the property.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="sellers_guide">
        <div class="container-fluid">
          <div class="sellers_guide_in">
            <div class="row align-items-center">
              <div class="col-sm-5">
                <img
                  class="max100p"
                  src={new_magicbricks_01}
                />
              </div>

              <div class="col-sm-7 text-center">
                <h3>
                  Download Our Home <span>Sellers Guide</span>
                </h3>

                <p>
                  Our Home sellers guide includes comprehensive information on
                  the selling process, pricing strategies, marketing tips, and
                  negotiating techniques to help you sell your home quickly and
                  at the best price possible.
                </p>

                <a href="#" class="btn btn-black" id="openModal">
                  Send Me A Copy
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

     <EstimateForm/>
     
    </>
  );
};

export default Sell;
